<template>
  <lab-task><lab-enthalpy-entropy-of-borax /></lab-task>
</template>

<script>
import LabEnthalpyEntropyOfBorax from '../simulations/LabEnthalpyEntropyOfBorax';
import LabTask from './labs/LabTask';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'EnthalpyEntropyOfBoraxSIM',
  components: {LabEnthalpyEntropyOfBorax, LabTask},
  mixins: [DynamicQuestionMixin()],
});
</script>
